import { Organization } from "@auth";
import { admin } from "./members";

export default {
  id: "loopOrganizationId",
  name: "Loop",
  logo: "https://source.boringavatars.com/bauhaus/120/loop",
  createdAt: new Date(2024, 0, 1),
  updatedAt: new Date(2024, 0, 1),
  members: [admin],
} as Organization;
