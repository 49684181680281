import BackendApi from "@api/BackendApi";
import { InitAuth } from "@auth/InitAuth.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { AuthHeaders, User } from "./AuthContext";

function useGetAccessToken() {
  const { error: auth0Error, getAccessTokenSilently, isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);

      getAccessTokenSilently()
        .then((accessToken) => setAccessToken(accessToken))
        .catch((error) => setError(error))
        .finally(() => setIsLoading(false));
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  return { accessToken, error: error || auth0Error, isLoading: isLoading || isAuth0Loading };
}

export function useInitAuth0(): InitAuth {
  const { loginWithRedirect, logout } = useAuth0();
  const { accessToken, error: accessTokenError, isLoading: isAccessTokenLoading } = useGetAccessToken();
  const [{ headers, user, error }, setBackendState] = useState<{
    headers?: AuthHeaders["headers"];
    user?: User;
    error?: Error;
  }>({});

  useEffect(() => {
    if (accessToken) {
      const headers = { Authorization: `Bearer ${accessToken}` };

      BackendApi.getUser({ headers: headers })
        .then((user) => setBackendState({ headers, user }))
        .catch((error) => setBackendState({ headers, error }));
    }
  }, [accessToken]);

  return {
    accessToken,
    error: error || accessTokenError,
    headers,
    isAuthenticated: user !== undefined,
    isLoading: isAccessTokenLoading || (!!accessToken && !user && !error),
    loginWithRedirect,
    logout: () => logout({ logoutParams: { returnTo: window.location.origin } }),
    user,
  };
}
