import { PathState, PathCollection } from "@models/project/PathCollection";
import {
  Vector3 as VP,
  Path as CPPPath,
  BezierPath as CPPBezierPath,
  HarmonicPath as CPPHarmonicPath,
} from "@variant-tech/pattern-derivation";

export enum PathActionType {
  // Make fast modifications in the main thread
  UPDATE_CPP,
  ADD_POINT,
  ADD_LAST_POINT,
  MOVE_POINT,
  REMOVE_POINT,
  REMOVE_POINTS,
  CLOSE,
  SEPARATE,
  CHANGE_VECTOR,
  REMOVE_VECTOR,
  // Update when the CPP worker responds.
  SET_STATE,
  SET_CURVES,
}

export type PathAction =
  | {
      type: PathActionType.UPDATE_CPP;
      cpp: CPPBezierPath | CPPHarmonicPath | CPPPath;
      pathType: "BezierPath" | "HarmonicPath" | "Path";
    }
  | {
      type: PathActionType.ADD_POINT;
      index: number;
      point: VP;
    }
  | {
      type: PathActionType.ADD_LAST_POINT;
      point: VP;
    }
  | {
      type: PathActionType.MOVE_POINT;
      index: number;
      point: VP;
    }
  | {
      type: PathActionType.REMOVE_POINT;
      index: number;
    }
  | {
      type: PathActionType.REMOVE_POINTS;
      indexes: Array<number>;
    }
  | {
      type: PathActionType.CLOSE;
    }
  | {
      type: PathActionType.SEPARATE;
      index: number;
    }
  | {
      type: PathActionType.CHANGE_VECTOR;
      index: number;
      vector: VP;
    }
  | {
      type: PathActionType.REMOVE_VECTOR;
      index: number;
    }
  | {
      type: PathActionType.SET_STATE;
      value: { points: Array<VP>; isLoop?: boolean; controlVectors?: Array<[VP, VP]> };
    }
  | {
      type: PathActionType.SET_CURVES;
      value: Array<Float32Array>;
    };

function insert<Type>(arr: Array<Type>, index: number, elem: Type): Array<Type> {
  return arr.slice(0, index).concat([elem], arr.slice(index));
}

function cutAndTie<Type>(arr: Array<Type>, index: number): Array<Type> {
  return arr.slice(index).concat(arr.slice(0, index));
}

export const reducer = (state: PathState, action: PathAction): PathState => {
  switch (action.type) {
    case PathActionType.UPDATE_CPP: {
      const { cpp, pathType } = action;
      return {
        ...state,
        cpp,
        type: pathType,
      };
    }
    case PathActionType.ADD_POINT: {
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        points: insert(state.points, action.index, action.point),
        controlVectors: insert(state.controlVectors, action.index, [
          [0, 0, 0],
          [0, 0, 0],
        ]),
      };
    }
    case PathActionType.ADD_LAST_POINT: {
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        points: [...state.points, action.point],
        controlVectors: [
          ...state.controlVectors,
          [
            [0, 0, 0],
            [0, 0, 0],
          ],
        ],
      };
    }
    case PathActionType.MOVE_POINT: {
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        points: state.points.map((elem, index) => (index === action.index ? action.point : elem)),
      };
    }
    case PathActionType.REMOVE_POINT: {
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        points: state.points.filter((_, index) => index !== action.index),
        controlVectors: state.controlVectors.filter((_, index) => index !== action.index),
      };
    }
    case PathActionType.REMOVE_POINTS: {
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        points: state.points.filter((_, index) => action.indexes.indexOf(index) == -1),
        controlVectors: state.controlVectors.filter((_, index) => action.indexes.indexOf(index) == -1),
      };
    }
    case PathActionType.CLOSE: {
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        isLoop: true,
      };
    }
    case PathActionType.SEPARATE: {
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        points: cutAndTie(state.points, action.index),
        controlVectors: cutAndTie(state.controlVectors, action.index),
        isLoop: false,
      };
    }
    case PathActionType.CHANGE_VECTOR: {
      const rVec = action.vector;
      const lVec: VP = [-rVec[0], -rVec[1], -rVec[2]];
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        controlVectors: state.controlVectors.map((elem, index) => (index === action.index ? [lVec, rVec] : elem)),
      };
    }
    case PathActionType.REMOVE_VECTOR: {
      return {
        ...state,
        cppSync: false,
        curveSync: false,
        controlVectors: state.controlVectors.map((elem, index) =>
          index === action.index
            ? [
                [0, 0, 0],
                [0, 0, 0],
              ]
            : elem,
        ),
      };
    }
    case PathActionType.SET_STATE: {
      const { points, isLoop, controlVectors } = action.value;
      return {
        ...state,
        cppSync: true,
        points,
        isLoop: isLoop ?? false,
        controlVectors: controlVectors ?? state.controlVectors,
      };
    }
    case PathActionType.SET_CURVES: {
      return {
        ...state,
        curveSync: true,
        curves: action.value,
      };
    }
  }
};

export const INITIAL_PATH: Omit<PathState, "cpp" | "id"> = {
  points: [],
  controlVectors: [],
  isLoop: false,
  cppSync: true,
  curveSync: true,
  type: "Path",
};

export const INITIAL_PATH_COLLECTION: Omit<PathCollection, "cpp" | "id" | "name"> = {
  paths: [],
};
