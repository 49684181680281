import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";
import { text } from "@design-system/text";
import { buttonStyleConfig } from "./ButtonStyleConfig";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle(({ theme, colorMode }) => ({
  button: {
    ...text["xs-regular"],
    ...buttonStyleConfig.variants?.dropdown,
  },
  list: {
    backdropFilter: "blur(10px)",
    bg: semanticTokens(theme, colorMode).surface.classic["primary-opacity"],
    border: "none",
    borderRadius: "none",
    py: "0",
    boxShadow: "lg",
  },
  item: {
    p: "0.75rem",
    bg: "transparent",
    color: semanticTokens(theme, colorMode).text.classic.primary,
    _hover: {
      bg: semanticTokens(theme, colorMode).surface.classic.secondary,
    },
    _active: {
      bg: semanticTokens(theme, colorMode).surface.invert.accent.secondary,
    },
  },
  groupTitle: {
    ...text.label,
    color: semanticTokens(theme, colorMode).text.classic.secondary,
    m: 0,
    p: "0.75rem",
  },
  divider: {
    borderColor: semanticTokens(theme, colorMode).border.classic.primary,
    borderBottom: "1px",
  },
}));

const dropdown = definePartsStyle(({ theme, colorMode }) => ({
  button: {
    ...buttonStyleConfig.variants?.dropdown,
  },
  command: {
    color: semanticTokens(theme, colorMode).icons.accent.primary,
  },
}));

const classicDropdown = definePartsStyle(({ theme, colorMode, ...rest }) => ({
  button: {
    ...buttonStyleConfig.variants?.["classic-dropdown"]({ theme, colorMode, ...rest }),
    color: semanticTokens(theme, colorMode).text.classic.primary,
  },
  command: {
    color: semanticTokens(theme, colorMode).icons.classic.secondary,
  },
}));

const contextMenu = definePartsStyle(({ theme, colorMode }) => ({
  button: {
    ...buttonStyleConfig.variants?.dropdown,
  },
  command: {
    color: semanticTokens(theme, colorMode).icons.accent.primary,
  },
  list: {
    width: "16.25rem",
  },
  item: {
    ...text["xs-regular"],
    p: "0.625rem 0.75rem",
  },
}));

const toggleIconDropdown = definePartsStyle(({ theme, colorMode, ...rest }) => ({
  button: {
    ...buttonStyleConfig.variants?.["toggleIconDropdown"]({ theme, colorMode, ...rest }),
  },
  list: {
    minWidth: "fit-content",
  },
}));

const toggleIconDropdownPrimary = definePartsStyle({
  list: {
    minWidth: "fit-content",
  },
});

export const menuStyleConfig = defineMultiStyleConfig({
  baseStyle,
  variants: {
    "classic-dropdown": classicDropdown,
    dropdown,
    contextMenu,
    toggleIconDropdown,
    toggleIconDropdownPrimary,
  },
});
