import "@/globals";
import { default as init, V3dApi } from "@variant-tech/pattern-derivation";

type V3dApiProps = { v3dApi?: V3dApi };

const V3dApiProps: V3dApiProps = {};

export default async function initV3dApi() {
  window.v3dApi = await init(false, false);
  return window.v3dApi;
}

export function getV3dApi() {
  if (!v3dApi) {
    throw new Error("V3dApi is not initialized");
  }

  return v3dApi;
}

export const useV3dApi = getV3dApi;
