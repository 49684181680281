import { defineStyleConfig, SystemStyleFunction } from "@chakra-ui/react";
import { semanticTokens } from "@design-system";

const primary: SystemStyleFunction = ({ theme, colorMode }) => ({
  borderColor: semanticTokens(theme, colorMode).border.border,
});

const inverse: SystemStyleFunction = ({ theme, colorMode }) => ({
  borderColor: semanticTokens(theme, colorMode).border.classic.primary,
});

export const separatorStyleConfig = defineStyleConfig({
  baseStyle: {
    borderWidth: 1,
    marginY: 2,
  },
  variants: {
    primary,
    inverse,
  },
  defaultProps: {
    variant: "primary",
  },
});

export type SeparatorVariant = "primary" | "inverse";
