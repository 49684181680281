import {
  AddOrganizationMemberRequest,
  BackendApi,
  RemoveOrganizationMembersRequest,
  UpdateOrganizationMemberRequest,
} from "@api/BackendApi";
import { loopAdminUser } from "@auth/mock";
import { v4 as randomUUID } from "uuid";
import { loop } from "./organizations";

export default {
  createProject: ({ body: { name, organizationId } }) =>
    Promise.resolve({
      id: randomUUID(),
      name,
      models: [],
      tags: [],
      members: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      organizationId,
    }),
  deleteProject: () => Promise.resolve(),
  getProjects: () => Promise.resolve({ projects: [] }),
  getProject: ({ params: { id } }) =>
    Promise.resolve({
      id,
      organizationId: loopAdminUser.organizations[0].organizationId,
      name: "Untitled",
      models: [],
      tags: [],
      members: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    }),

  patchProject: ({ params: { projectId }, body: { name } }) =>
    Promise.resolve({
      id: projectId,
      name,
      models: [],
      tags: [],
      members: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      organizationId: loopAdminUser.organizations[0].organizationId,
    }),

  addProjectModel: ({ content }) =>
    Promise.resolve({
      id: randomUUID(),
      name: content.name,
      url: (window.URL || window.webkitURL).createObjectURL(content),
      pathCollections: [],
    }),
  decimateProjectModel: ({ params: { id }, content }) =>
    Promise.resolve({
      id,
      name: content.name,
      url: (window.URL || window.webkitURL).createObjectURL(content),
      decimatedUrl: (window.URL || window.webkitURL).createObjectURL(content),
      pathCollections: [],
    }),
  patchProjectModel: ({ params: { id }, body }) =>
    Promise.resolve({
      id,
      name: "Untitled",
      url: "http://localhost:3000",
      pathCollections: [],
      ...body,
    }),
  updateProjectThumbnail: () => Promise.resolve(),
  createPathCollection: ({ body, params: { projectId, modelId } }) =>
    Promise.resolve({
      ...body,
      projectId,
      modelId,
      id: randomUUID(),
    }),
  updatePathCollection: ({ body, params: { projectId, modelId, pathCollectionId } }) =>
    Promise.resolve({ ...body, projectId, modelId, pathCollectionId }),
  deletePathCollection: () => Promise.resolve(),
  createProcess: ({ params: { projectId } }) =>
    Promise.resolve({
      id: randomUUID(),
      status: "ready",
      input: { glbUrl: "key.glb" },
      projectId,
    }),
  getProcess: ({ params: { id, projectId } }) =>
    Promise.resolve({
      id,
      status: "done",
      input: { glbUrl: "key.glb" },
      output: { bmpUrl: "key.bmp" },
      projectId,
    }),
  getOrganizationMembers: ({ params: { organizationId } }) =>
    Promise.resolve({
      members: loop.members,
      organizationId,
    }),
  getUser: () => Promise.resolve(loopAdminUser),
  addOrganizationMember: ({ body, params }: AddOrganizationMemberRequest) =>
    Promise.resolve({
      ...params,
      ...body,
      name: body.name ?? body.email,
      id: randomUUID(),
    }),
  updateOrganizationMember: (request: UpdateOrganizationMemberRequest) => Promise.resolve(request.body),
  removeOrganizationMembers: (request: RemoveOrganizationMembersRequest) =>
    Promise.resolve({ organizationId: request.params.organizationId, members: loop.members }),
} as BackendApi;
