import { loop, loopAdmin } from "@api/mock";
import { OrganizationMembership, User } from "@auth/AuthContext";

export default {
  ...loopAdmin,
  organizations: [
    {
      organizationId: loop.id,
      memberStatus: "active",
      memberRole: "admin",
      organization: loop,
    } as OrganizationMembership,
  ],
} as User;
