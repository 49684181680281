import { Box, ScreenSizer } from "@react-three/drei";
import { forwardRef, useEffect, useState } from "react";
import { BufferGeometry, Material, Mesh, NormalBufferAttributes, Object3DEventMap, Vector3 } from "three";
import { workspace3DTokens } from "./workspace3D-tokens";
import { ThreeEvent } from "@react-three/fiber";

type ClickTargetProps = {
  scale: number;
  tolerance?: number;
  position: Vector3;
  onClick: (e: ThreeEvent<MouseEvent>) => void;
  onHoveredChange?: (hovered: boolean) => void;
  userData?: Record<string, any>;
};

type RefType = Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>;

export const ClickTarget = forwardRef<RefType, ClickTargetProps>(function (
  {
    scale,
    tolerance = workspace3DTokens.clickTarget.tolerance,
    position,
    onClick,
    onHoveredChange,
    userData,
  }: ClickTargetProps,
  ref,
) {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (onHoveredChange) onHoveredChange(isHovered);
  }, [isHovered]);

  return (
    <ScreenSizer position={position}>
      <Box
        args={[scale + tolerance, scale + tolerance, scale + tolerance]}
        position={0}
        onClick={onClick}
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
        ref={ref}
        userData={userData}
      >
        <meshBasicMaterial visible={false} />
      </Box>
    </ScreenSizer>
  );
});
