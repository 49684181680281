import { Fragment, memo, useMemo, useRef, useState } from "react";
import { BufferAttribute, BufferGeometry, Vector3, CatmullRomCurve3, Mesh } from "three";
import { Html, Line } from "@react-three/drei";
import { useOcclusionChecker } from "./useOcclusionChecker";
import { useFrame } from "@react-three/fiber";
import { useLineVizStore } from "./VizCustomizationStore";
import { useProjectState } from "@state/project";

export type PathSegmentProps = {
  mesh: Mesh;
  collectionId?: string;
  modelId?: string;
  curve?: Float32Array;
  length?: number | undefined;
  color: string;
  opacity?: number;
  lineWidth: number;
  showLengthOverride: boolean; // TODO: adding this to force show length on one segment only for now
  isHovered?: boolean;
  isSelected?: boolean;
  handleOnPointerEnter?: () => void;
  handleOnPointerLeave?: () => void;
  handleOnClick?: () => void;
};

export const PathSegment = memo(function PathSegment({
  curve,
  mesh,
  collectionId,
  modelId,
  length,
  color,
  opacity: initialOpacity = 1.0,
  lineWidth,
  showLengthOverride,
  isHovered,
  isSelected,
  handleOnPointerEnter,
  handleOnPointerLeave,
  handleOnClick,
}: PathSegmentProps) {
  const {
    showLine,
    showTube,
    dashed,
    dashSize,
    dashScale,
    dashOffset,
    // color,
    // lineWidth,
    outline,
    outlineColor,
    outlineOffset,
    occludedOpacity,
  } = useLineVizStore((state) => state.lineVizOptions);

  const { showMeasurements } = useProjectState();

  const { checkForOcclusionNoCallback } = useOcclusionChecker({ mesh });

  const [segments, setSegments] = useState<[boolean, number[]][]>([]);

  const numSegments = useRef(1);

  function ToVertices(geometry: BufferGeometry) {
    const positions = geometry.attributes.position;

    const vertices: Vector3[] = [];
    for (let index = 0; index < positions.count; index++) {
      vertices.push(new Vector3().fromBufferAttribute(geometry.attributes.position, index));
    }

    return vertices;
  }

  const vertices = useMemo(() => {
    if (!curve) {
      return null;
    }
    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new BufferAttribute(curve, 3));

    return ToVertices(geometry);
  }, [curve]);

  const tubeGeo = useMemo(() => {
    if (!vertices) {
      return null;
    }

    const catmull = new CatmullRomCurve3(vertices, false, "catmullrom", 0.0);

    numSegments.current = vertices.length;

    return catmull;
  }, [curve]);

  // tube geo
  const TubeGeo = () => {
    if (!tubeGeo) {
      return null;
    }

    if (tubeGeo.points.length === 0) {
      return null;
    }

    return (
      <>
        <mesh
          name={"curve-path"}
          onPointerOver={() => {
            console.log("entered");
          }}
        >
          <tubeGeometry args={[tubeGeo, numSegments.current, 0.03, 6, false]} />
          <meshBasicMaterial color={color} />
        </mesh>
      </>
    );
  };

  function segmentArrayWithIndexedArrays(arr: boolean[]): [boolean, number[]][] {
    const result: [boolean, number[]][] = [];
    let previousValue: boolean | undefined = undefined;
    let currentIndexes: number[] = [];

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== previousValue) {
        if (currentIndexes.length > 0) {
          result.push([previousValue!, currentIndexes]);
        }
        currentIndexes = [i];
        previousValue = arr[i];
      } else {
        currentIndexes.push(i);
      }
    }

    if (currentIndexes.length > 0) {
      result.push([previousValue!, currentIndexes]);
    }

    return result;
  }

  useFrame(() => {
    if (!vertices) {
      return;
    }

    const occlusions = vertices.map((vertex) => {
      return checkForOcclusionNoCallback(vertex.toArray());
    });

    setSegments(segmentArrayWithIndexedArrays(occlusions));
  });

  // TODO: move this to a Path / PathSegment services file
  const lengthCallout = (length: number | undefined, segmentVertices: Vector3[], occluded: boolean) => {
    if (!showLengthOverride || !length || !showMeasurements) return;

    // TODO: get midpoint from sampler
    const midPoint = segmentVertices[Math.floor(segmentVertices.length * 0.5)].clone();

    // TODO: conceive of better way to hide / move callout if the segment is short
    // if (segmentVertices.length < 10) {
    //   return;
    // }

    return (
      <Html
        position={midPoint}
        distanceFactor={10}
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "10px",
          paddingBottom: "10px",
          background: "#444",
          color: "white",
          transition: "all 0.25s",
          opacity: occluded ? 0 : 1,
          userSelect: "none",
          // transform: `scale(${occluded ? 0.5 : 1})`,
        }}
        center
      >
        {length.toFixed(2)}cm
      </Html>
    );
  };

  const occludedPaths = () => {
    if (!vertices || vertices.length === 0) {
      return null;
    }

    const content: JSX.Element[] = [];

    const middle = Math.floor(segments.length / 2);

    segments.map((segment, segmentIndex) => {
      const [occluded, indexes] = segment;
      const segmentVertices = indexes.map((index) => vertices[index]).filter((v) => v !== undefined);

      if (segmentVertices.every((v) => v === undefined)) {
        return;
      }

      let opacity = initialOpacity;
      if (occluded) {
        opacity = isHovered || isSelected ? occludedOpacity : 0.0;
      }

      content.push(
        <Fragment key={segmentIndex}>
          <Line
            points={segmentVertices}
            color={outlineColor}
            lineWidth={lineWidth + outlineOffset + 15}
            renderOrder={999}
            dashed={true}
            dashScale={dashed ? dashScale : 0.0}
            dashSize={dashed ? dashSize : 1.0}
            dashOffset={dashOffset}
            depthTest={false}
            transparent
            visible={outline}
            opacity={opacity}
            onPointerEnter={() => {
              if (!occluded && handleOnPointerEnter) handleOnPointerEnter();
            }}
            onPointerLeave={() => {
              if (handleOnPointerLeave) handleOnPointerLeave();
            }}
            onClick={() => {
              if (!occluded && handleOnClick) handleOnClick();
            }}
            userData={{ modelId, collectionId, occluded }}
          />
          <Line
            points={segmentVertices}
            color={color}
            lineWidth={lineWidth}
            renderOrder={0}
            dashed={true}
            dashScale={dashed ? dashScale : 0.0}
            dashSize={dashed ? dashSize : 1.0}
            dashOffset={dashOffset}
            depthTest={false}
            transparent
            opacity={opacity}
            userData={{ modelId, collectionId, occluded }}
          />
          {segmentIndex === middle && lengthCallout(length, segmentVertices, occluded)}
        </Fragment>,
      );
    });

    return content;
  };

  return (
    <>
      {showTube && <TubeGeo />}
      {showLine && occludedPaths()}
    </>
  );
});
