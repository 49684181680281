import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/styled-system";
import { ChakraTheme } from "@chakra-ui/theme";
import { semanticTokens } from "@design-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const primary = definePartsStyle(({ theme, colorMode }: StyleFunctionProps) => ({
  td: {
    borderColor: semanticTokens(theme, colorMode).border.border,
    borderBottomWidth: 1,
  },
  th: {
    borderColor: semanticTokens(theme, colorMode).border.border,
    borderBottomWidth: 1,
  },
  tr: {
    _hover: {
      backgroundColor: semanticTokens(theme, colorMode).surface.secondary,
    },
  },
}));

export const tableStyleConfig: Partial<ChakraTheme["components"]["Table"]> = defineMultiStyleConfig({
  variants: {
    primary,
  },
  defaultProps: {
    variant: "primary",
  },
});
