import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { text } from "@design-system/text";

function objectMap<T, R>(object: Record<string, T>, fn: (value: T) => R): Record<string, R> {
  const result: Record<string, R> = {};
  Object.keys(object).forEach((key) => {
    result[key] = fn(object[key]);
  });
  return result;
}

const variants = objectMap(text, (it) => defineStyle(it));

export const textStyleConfig = defineStyleConfig({
  baseStyle: {
    font: "inter",
    fontSize: "sm",
    fontWeight: "normal",
    borderRadius: 0,
  },
  variants,
});
