import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { headings } from "@design-system/headings";

function objectMap<T, R>(object: Record<string, T>, fn: (value: T) => R): Record<string, R> {
  const result: Record<string, R> = {};
  Object.keys(object).forEach((key) => {
    result[key] = fn(object[key]);
  });
  return result;
}

const sizes = objectMap(headings, (it) => defineStyle(it));

export const headingStyleConfig = defineStyleConfig({
  baseStyle: {
    font: "inter",
    fontSize: "sm",
    fontWeight: "normal",
    borderRadius: 0,
  },
  sizes,
});
