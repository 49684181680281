import { colors } from "@design-system/colors";

const path = {
  guide: {
    opacity: 1.0,
    color: colors.green["600"],
  },
  zone: {
    opacity: 0.4,
    color: colors.gray["650"],
  },
  unused: {
    opacity: 0.15,
    color: colors.gray["650"],
  },
  section: {
    opacity: 0.4,
    color: colors.orange["600"],
  },
};

const surfacePoint = {
  innerRadius: 4,
  outerRadius: 6,
};

const bezierHandle = {
  innerRadius: 3,
  outerRadius: 5,
};

const clickTarget = {
  tolerance: 2,
};

const penTool = {
  minDistance: surfacePoint.outerRadius + clickTarget.tolerance,
};

export const workspace3DTokens = {
  path,
  surfacePoint,
  bezierHandle,
  clickTarget,
  penTool,
};
