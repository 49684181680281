import { Theme } from "@chakra-ui/react";
import { useLocalState } from "@hooks";
import { createContext, PropsWithChildren } from "react";
import { themes } from "./themes.ts";

export type ThemeContextProps = {
  chakraTheme: Partial<Theme>;
  selectedTheme: keyof typeof themes;
  setSelectedTheme: (theme: ThemeContextProps["selectedTheme"]) => void;
};

const defaultValue: ThemeContextProps = {
  chakraTheme: themes["base"],
  selectedTheme: "base",
  setSelectedTheme() {
    throw new Error("Unexpected usage of uninitialized Context Value");
  },
};

export const SelectedThemeContext = createContext<ThemeContextProps>(defaultValue);

export function SelectedThemeProvider({ children }: PropsWithChildren) {
  const [selectedTheme, setSelectedTheme] = useLocalState<ThemeContextProps["selectedTheme"]>(
    "selectedTheme",
    defaultValue.selectedTheme,
  );

  return (
    <SelectedThemeContext.Provider
      value={{
        get chakraTheme() {
          return themes[this.selectedTheme];
        },
        selectedTheme,
        setSelectedTheme,
      }}
    >
      {children}
    </SelectedThemeContext.Provider>
  );
}
