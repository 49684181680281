import Hotjar from "@hotjar/browser";

export function initHotjar() {
  if (import.meta.env.PROD) {
    const siteId = import.meta.env.HOTJAR_SITE_ID ?? 3854176;
    const hotjarVersion = import.meta.env.HOTJAR_VERSION ?? 6;

    Hotjar.init(siteId, hotjarVersion);
  }
}
