import { defineStyleConfig } from "@chakra-ui/react";
import { semanticTokens } from "@design-system";
import { text } from "@design-system/text.ts";

export const toastStyleConfig = defineStyleConfig({
  baseStyle: ({ theme, colorMode }) => ({
    container: {
      ...text["s-medium"],
      bg: semanticTokens(theme, colorMode).surface.classic["primary-opacity"],
      backdropFilter: "blur(10px)",
      boxShadow: "lg",
      color: semanticTokens(theme, colorMode).text.classic.primary,
      p: "0.75rem",
      w: "20rem",
    },
    children: {
      ...text["xs-regular"],
      color: semanticTokens(theme, colorMode).text.classic.secondary,
      w: "full",
    },
    icon: {
      color: semanticTokens(theme, colorMode).text.classic.primary,
      size: "md",
    },
  }),
  variants: {
    info: ({ theme, colorMode }) => ({
      icon: {
        color: semanticTokens(theme, colorMode).text.classic.primary,
      },
    }),
    success: ({ theme, colorMode }) => ({
      container: {
        borderColor: semanticTokens(theme, colorMode).border.success,
        borderWidth: 1,
      },
      icon: {
        color: semanticTokens(theme, colorMode).icons.success,
      },
    }),
    error: ({ theme, colorMode }) => ({
      container: {
        borderColor: semanticTokens(theme, colorMode).border.accent.primary,
        borderWidth: 1,
      },
      icon: {
        color: semanticTokens(theme, colorMode).icons.accent.primary,
      },
    }),
    warning: ({ theme, colorMode }) => ({
      container: {
        borderColor: semanticTokens(theme, colorMode).border.warning,
        borderWidth: 1,
      },
      icon: {
        color: semanticTokens(theme, colorMode).icons.warning,
      },
    }),
  },
});

export type ToastVariant = "info" | "success" | "error" | "warning";
