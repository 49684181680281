import { Vector3, BufferGeometry, Mesh, Raycaster } from "three";
import { useThree } from "@react-three/fiber";
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast, MeshBVHOptions, MeshBVH, SAH } from "three-mesh-bvh";

declare module "three" {
  interface BufferGeometry {
    computeBoundsTree(options?: MeshBVHOptions): MeshBVH;
    disposeBoundsTree(): void;
    boundsTree?: MeshBVH;
  }
}

// Add the extension functions
BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

type useOcclusionCheckerProps = {
  mesh: Mesh;
};

export function useOcclusionChecker({ mesh }: useOcclusionCheckerProps) {
  const { camera } = useThree();

  function checkForOcclusion(point: number[], onOcclusionChange: (occluded: boolean) => void) {
    if (!mesh.geometry.boundsTree) {
      console.log("Building bounds tree");
      mesh.geometry.computeBoundsTree({ maxLeafTris: 1, strategy: SAH });
    }

    const tempRaycaster = new Raycaster();

    const v = new Vector3();
    v.copy(camera.position).sub(new Vector3(...point));

    tempRaycaster.set(new Vector3(...point), v);

    const intersections = tempRaycaster.intersectObject(mesh);
    const tolerance = intersections.some((i) => i.point.distanceTo(new Vector3(...point)) > 0.1);

    if (intersections.length > 0 && tolerance) {
      onOcclusionChange(true);
    } else {
      onOcclusionChange(false);
    }
  }

  function checkForOcclusionNoCallback(point: number[]) {
    if (!mesh.geometry.boundsTree) {
      console.log("Building bounds tree");
      mesh.geometry.computeBoundsTree({ maxLeafTris: 1, strategy: SAH });
    }

    const tempRaycaster = new Raycaster();

    const v = new Vector3();
    v.copy(camera.position).sub(new Vector3(...point));

    tempRaycaster.set(new Vector3(...point), v);

    const intersections = tempRaycaster.intersectObject(mesh);
    const tolerance = intersections.some((i) => i.point.distanceTo(new Vector3(...point)) > 0.1);

    if (intersections.length > 0 && tolerance) {
      return true;
    } else {
      return false;
    }
  }

  return {
    checkForOcclusion,
    checkForOcclusionNoCallback,
  };
}
