import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { spacing } from "@design-system";
import { semanticTokens } from "@design-system/semantic-tokens";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

export const accordionStyleConfig = defineMultiStyleConfig({
  baseStyle: definePartsStyle(({ theme, colorMode }) => ({
    root: {
      border: "none",
    },
    button: {
      padding: 0,
      paddingBottom: spacing.space[200],
      textColor: semanticTokens(theme, colorMode).text.classic.secondary,
      justifyContent: "space-between",
    },
    container: {
      border: "none",
    },
    panel: {
      padding: "0",
      display: "flex",
      flexDirection: "column",
      gap: spacing.space[200],
      mt: spacing.space[100],
    },
  })),
  variants: {
    outline: definePartsStyle(({ theme, colorMode }) => ({
      root: {
        border: "",
        borderColor: semanticTokens(theme, colorMode).border.classic.primary,
        borderBottomWidth: "1px",
        borderTopWidth: "1px",
      },
      container: {
        border: "",
      },
    })),
  },
});
