export function recordToArray<T>(record: Record<string, T>): T[] {
  return Object.values(record);
}

export function arrayToRecord<T, R = T>(
  array: T[],
  keyFn: (value: T) => string,
  valueFn: (value: T) => R = (value) => value as unknown as R,
): Record<string, R> {
  return array.reduce(
    (acc, value) => {
      const key = keyFn(value);
      acc[key] = valueFn(value);
      return acc;
    },
    {} as Record<string, R>,
  );
}
