import { defineStyleConfig, SystemStyleFunction } from "@chakra-ui/react";
import { semanticTokens } from "@design-system";

const primary: SystemStyleFunction = ({ theme, colorMode }) => ({
  background: semanticTokens(theme, colorMode).surface.classic.primary,
  borderColor: semanticTokens(theme, colorMode).border.classic.primary,
  color: semanticTokens(theme, colorMode).text.classic.primary,
});

const secondary: SystemStyleFunction = ({ theme, colorMode }) => ({
  background: semanticTokens(theme, colorMode).surface.classic.secondary,
  borderColor: semanticTokens(theme, colorMode).border.classic.secondary,
  color: semanticTokens(theme, colorMode).text.classic.secondary,
});

export const toolbarStyleConfig = defineStyleConfig({
  baseStyle: {
    gap: "0",
    padding: "0",
    width: "fit-content",
    height: "fit-content",
  },
  sizes: {
    "column-full": {
      height: "full",
    },
    "row-full": {
      width: "full",
    },
  },
  variants: {
    primary,
    secondary,
  },
  defaultProps: {
    variant: "primary",
  },
});

export type ToolbarVariant = "primary" | "secondary";
