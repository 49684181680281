export const text = {
  "xl-bold": {
    fontSize: 18,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.625rem",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textTransform: "none",
  },
  "xl-regular": {
    fontSize: 18,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.625rem",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textTransform: "none",
  },
  l: {
    fontSize: 17,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.5rem",
    paragraphIndent: 0,
    paragraphSpacing: 16,
    textTransform: "none",
  },
  "m-bold": {
    fontSize: 16,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textTransform: "none",
  },
  "m-semibold": {
    fontSize: 16,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  "m-regular": {
    fontSize: 16,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  "navbar-link": {
    fontSize: 14,
    textDecoration: "none",
    fontFamily: "Mabry Pro, sans-serif",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0.84,
    lineHeight: "1.05rem",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textTransform: "uppercase",
  },
  "s-semibold": {
    fontSize: 14,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  "s-medium": {
    fontSize: "0.875rem",
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  "s-regular": {
    fontSize: 14,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  "xs-semibold": {
    fontSize: 13,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  "xs-regular": {
    fontSize: 13,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  "2xs-medium": {
    fontSize: 12,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.125rem",
    paragraphIndent: 0,
    paragraphSpacing: 12,
    textTransform: "none",
  },
  "2xs-regular": {
    fontSize: 12,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  "3xs-bold": {
    fontSize: 10,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "0.75rem",
    paragraphIndent: 0,
    paragraphSpacing: 12,
    textTransform: "uppercase",
  },
  "3xs-medium": {
    fontSize: 10,
    textDecoration: "none",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.25rem",
    paragraphIndent: 0,
    paragraphSpacing: 14,
    textTransform: "none",
  },
  label: {
    fontSize: 12,
    textDecoration: "none",
    fontFamily: "Mabry Pro, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    lineHeight: "1.125rem",
    paragraphIndent: 0,
    paragraphSpacing: 12,
    textTransform: "uppercase",
  },
};
