export const headings = {
  h1: {
    fontSize: "34px",
    textDecoration: "none",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0",
    lineHeight: "40.8px",
    textTransform: "none",
  },
  h2: {
    fontSize: "30px",
    textDecoration: "none",
    fontWeight: 900,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0",
    lineHeight: "26px",
    textTransform: "uppercase",
  },
  h3: {
    fontSize: "22px",
    textDecoration: "none",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0",
    lineHeight: "26.4px",
    textTransform: "none",
  },
  h4: {
    fontSize: "20px",
    textDecoration: "none",
    fontWeight: 900,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0",
    lineHeight: "26px",
    textTransform: "uppercase",
  },
  h5: {
    fontSize: "10px",
    textDecoration: "none",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0",
    lineHeight: "18px",
    textTransform: "uppercase",
  },
};
