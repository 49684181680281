import { defineStyleConfig, SystemStyleFunction, SystemStyleInterpolation } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";
import { text } from "@design-system/text";

const primary: SystemStyleFunction = ({ theme, colorMode }) => ({
  bg: semanticTokens(theme, colorMode).surface.primary,
  color: semanticTokens(theme, colorMode).icons.primary,
  _hover: {
    bg: semanticTokens(theme, colorMode).surface.tertiary,
  },
});

const secondary: SystemStyleFunction = ({ theme, colorMode }) => ({
  bg: semanticTokens(theme, colorMode).surface.secondary,
  color: semanticTokens(theme, colorMode).icons.secondary,
  _hover: {
    bg: semanticTokens(theme, colorMode).surface.tertiary,
  },
});

const momentary: SystemStyleFunction = ({ theme, colorMode }) => ({
  bg: semanticTokens(theme, colorMode).surface.secondary,
  color: semanticTokens(theme, colorMode).icons.primary,
});

const inverse: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  color: semanticTokens(theme, colorMode).text.classic.primary,
  _hover: {
    background: semanticTokens(theme, colorMode).surface.classic.secondary,
  },
});

const secondaryInverse: SystemStyleFunction = ({ theme, colorMode }) => ({
  bg: semanticTokens(theme, colorMode).surface.classic.secondary,
  color: semanticTokens(theme, colorMode).icons.classic.secondary,
  _hover: {
    bg: semanticTokens(theme, colorMode).surface.classic.tertiary,
  },
});

export const toggleButtonStyleConfig = defineStyleConfig({
  baseStyle: ({ theme, colorMode }) => ({
    ...text["xs-regular"],
    gap: "0.125rem",
    borderRadius: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transitionDuration: "0.2s",
    _disabled: {
      opacity: 0.5,
    },
    _active: {
      bg: semanticTokens(theme, colorMode).surface.invert.accent.secondary,
      color: semanticTokens(theme, colorMode).icons.accent.primary,
    },
  }),
  sizes: {
    xs: {
      height: "12px",
      width: "12px",
    },
    sm: {
      height: "32px",
      width: "32px",
    },
    md: {
      height: "44px",
      width: "44px",
    },
  },
  variants: {
    primary,
    secondary,
    momentary,
    inverse,
    "secondary-inverse": secondaryInverse,
  },
  defaultProps: {
    size: "md",
    variant: "secondary",
  },
});

export type ToggleButtonStyleVariant = "primary" | "secondary" | "momentary" | "inverse" | "secondary-inverse";
