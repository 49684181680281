import { useColorMode } from "@chakra-ui/color-mode";
import { useTheme } from "@chakra-ui/react";
import { semanticTokens, SemanticTokens } from "@design-system";

export function useSemanticTokens(): SemanticTokens {
  const theme = useTheme();
  const colorMode = useColorMode();

  return semanticTokens(theme, colorMode.colorMode);
}
