import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle(({ theme, colorMode }) => ({
  thumb: {
    bg: "gray.900",
    _checked: {
      bg: "gray.100",
    },
  },
  track: {
    bg: semanticTokens(theme, colorMode).surface.quaternary,
    _checked: {
      bg: semanticTokens(theme, colorMode).surface.invert.accent.primary,
    },
  },
}));

export const switchStyleConfig = defineMultiStyleConfig({ baseStyle });
