import { progressAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(progressAnatomy.keys);

const baseStyle = definePartsStyle(({ theme, colorMode }) => ({
  track: {
    bg: semanticTokens(theme, colorMode).surface.classic.tertiary,
  },
  filledTrack: {
    bg: semanticTokens(theme, colorMode).surface.invert.accent.primary,
  },
}));

export const progressStyleConfig = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    xs: {
      track: {
        height: "2px",
      },
    },
    sm: {
      track: {
        height: "4px",
      },
    },
    md: {
      track: {
        height: "8px",
      },
    },
    lg: {
      track: {
        height: "12px",
      },
    },
    xl: {
      track: {
        height: "16px",
      },
    },
  },
  variants: {
    shine: ({ theme, colorMode }) => ({
      filledTrack: {
        boxShadow: `0px 0px 20px 2px ${semanticTokens(theme, colorMode).surface.invert.accent.primary}`,
      },
      track: {
        overflow: "unset",
      },
    }),
    low: ({ theme, colorMode }) => ({
      track: {
        bg: semanticTokens(theme, colorMode).surface.classic.secondary,
      },
      filledTrack: {
        bg: semanticTokens(theme, colorMode).border.border,
      },
    }),
    medium: ({ theme, colorMode }) => ({
      track: {
        bg: semanticTokens(theme, colorMode).surface.classic.secondary,
      },
      filledTrack: {
        bg: semanticTokens(theme, colorMode).surface.warning,
      },
    }),
    high: ({ theme, colorMode }) => ({
      track: {
        bg: semanticTokens(theme, colorMode).surface.classic.secondary,
      },
      filledTrack: {
        bg: semanticTokens(theme, colorMode).surface.error,
      },
    }),
  },
  defaultProps: {
    size: "sm",
  },
});
