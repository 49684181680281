import { BoxProps, Center, Spinner, VStack } from "@chakra-ui/react";
import { useSemanticTokens } from "@design-system";

export function Loading(props: BoxProps) {
  return (
    <Center as={VStack} alignContent="center" {...props}>
      <Spinner w="10rem" h="10rem" color={useSemanticTokens().border.accent.primary} thickness="0.75rem" speed="1s" />
    </Center>
  );
}
