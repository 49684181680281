import { create } from "zustand";
import { Project, Member, Tag } from "@models/backend";
import { createJSONStorage, persist } from "zustand/middleware";

export type ProjectColumnSorting = {
  id: keyof (Project | Folder) | string;
  desc: boolean;
};

export type ProjectSortingState = ProjectColumnSorting[];

export interface ProjectsToolbarState {
  sort: ProjectSortingState;
  viewMode: "list" | "grid";

  setSort: (sort: ProjectsToolbarState["sort"]) => void;
  setViewMode: (viewMode: ProjectsToolbarState["viewMode"]) => void;
}

export interface Folder {
  id: string;
  organizationId: string;
  name: string;
  children: Project[];
  tags: Tag[];
  members: Member[];
  createdAt: Date;
  updatedAt: Date;
  parentFolderId?: string;
}

export interface ProjectsTableState {
  filterResult: Project[];
}

export interface ProjectsDataState {
  projects: Project[];

  setProjects: (projects: Project[]) => void;
}

type ProjectsState = ProjectsToolbarState;

export const useProjectsState = create(
  persist<ProjectsState>(
    (set) => {
      return {
        sort: [{ id: "updatedAt", desc: true }],
        viewMode: "grid",
        setSort: (sort) => set(() => ({ sort })),
        setViewMode: (viewMode) => set(() => ({ viewMode })),
      };
    },
    {
      name: "projects-state",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
