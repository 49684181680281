import { Button, Center } from "@chakra-ui/react";
import {
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayCanvasIntegration,
  replayIntegration,
  withErrorBoundary,
  withProfiler,
} from "@sentry/react";
import React, { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export function initSentry(App: React.FC) {
  if (import.meta.env.PROD) {
    init({
      dsn: "https://25c7309dd0f40e89238fdc44edab1288@o4506616448090112.ingest.us.sentry.io/4507917514309632",
      integrations: [
        browserProfilingIntegration(),
        browserTracingIntegration(),
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
        replayCanvasIntegration(),
      ],
      profilesSampleRate: 1.0,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [/^\/api/],
      tracesSampleRate: 1.0,
    });

    return withProfiler(
      withErrorBoundary(App, {
        fallback: ({ error, eventId, componentStack }) => (
          <Center flex="1 1 auto" h="full">
            It's not you! It's us.
            <br />
            Error <code>{eventId}</code> has been reported
            <div>
              <code>{error?.toString()}</code>
              <pre>{componentStack}</pre>
            </div>
            <Button variant="primary" onClick={() => window.location.reload()}>
              Go back
            </Button>
          </Center>
        ),
      }),
    );
  }

  return App;
}
