function scale(base: number, ratio: number) {
  return (base * ratio) / 16.0 + "rem";
}

export const spacing = {
  space: {
    50: scale(2, 50 / 50),
    100: scale(2, 100 / 50),
    200: scale(2, 200 / 50),
    300: scale(2, 300 / 50),
    400: scale(2, 400 / 50),
    600: scale(2, 600 / 50),
    800: scale(2, 800 / 50),
  },
};
