import { refreshPathCollections } from "@utils/project/model.ts";
import { Model } from "@models/project";
import { ModelsStore, PathCollections } from "../types";
import { GetState, SetState } from "zustand";
import { Units } from "@models/backend";
import { scaleMesh } from "@utils/project/mesh.ts";
import { arrayToRecord } from "@utils/project/collection";
import { createPathCollection } from "@utils/project/pathCollections";

export const modelsSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>) => ({
  models: [],
  addModel: async (model: Model) => {
    const newCollection = await createPathCollection(model, "Path");
    set(({ models, pathCollections }: ModelsStore) => ({
      models: [...models, model],
      pathCollections: {
        ...pathCollections,
        [model.id]: {
          collections: {},
          newCollection,
        },
      },
      selectedObjects: [{ type: "model", id: model.id }],
    }));
  },
  setModels: async (models: Model[]) => {
    const newCollections = await Promise.all(models.map((model) => createPathCollection(model, "Path")));
    set(() => {
      const pathCollections: PathCollections = arrayToRecord(
        models,
        (model) => model.id,
        (model) => ({
          collections: {},
          newCollection: newCollections[models.findIndex((m) => m.id === model.id)],
        }),
      );
      return { models, pathCollections };
    });
  },
  updateModel: (id: string, update: Partial<Model>) =>
    set(({ models }: ModelsStore) => {
      const index = models.findIndex((model) => model.id === id);
      if (index === -1) {
        console.error("updateModel", "item not found", { id });
        return { models };
      }
      const result = [...models];
      result[index] = { ...models[index], ...update };
      return { models: result };
    }),
  updateModelUnit: async (modelId: Model["id"], unit: Model["unit"]) => {
    const { models, updateModel, dispatchPathEdit, dispatchPathNew } = get();
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;

    const { mesh: oldMesh, mesh3DBase } = model;
    const scale = Units[unit];
    const { mesh } = await scaleMesh({ mesh: oldMesh, mesh3DBase }, scale);

    await refreshPathCollections(model.id, get().pathCollections, dispatchPathEdit, dispatchPathNew);

    return updateModel(modelId, {
      mesh,
      scale,
      unit,
    });
  },

  updateProjectData: (projectData: { models: Model[]; pathCollections: PathCollections }) => {
    set(() => ({
      models: projectData.models,
      pathCollections: projectData.pathCollections,
    }));
  },
});
