import { defineStyleConfig, SystemStyleFunction } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";

const primary: SystemStyleFunction = ({ theme, colorMode }) => ({
  color: semanticTokens(theme, colorMode).icons.primary,
});

const secondary: SystemStyleFunction = ({ theme, colorMode }) => ({
  color: semanticTokens(theme, colorMode).icons.accent.primary,
});

const tertiary: SystemStyleFunction = ({ theme, colorMode }) => ({
  color: semanticTokens(theme, colorMode).icons.accent.tertiary,
});

const secondaryInverse: SystemStyleFunction = ({ theme, colorMode }) => ({
  color: semanticTokens(theme, colorMode).icons.classic.secondary,
});

export const iconStyleConfig = defineStyleConfig({
  baseStyle: {
    flexShrink: 0,
    fontSize: "16px",
  },
  sizes: {
    sm: {
      height: "16px",
      width: "16px",
      strokeWidth: "1px",
    },
    md: {
      height: "20px",
      width: "20px",
      strokeWidth: "1.25px",
    },
    lg: {
      height: "24px",
      width: "24px",
      strokeWidth: "1.5px",
    },
  },
  variants: {
    primary,
    secondary,
    tertiary,
    "secondary-inverse": secondaryInverse,
  },
});
