import { User } from "@auth";
import { InitAuth } from "@auth/InitAuth.ts";
import { loopAdminUser } from "@auth/mock/index.ts";
import { useState } from "react";

type AuthState = {
  accessToken?: string;
  headers?: { Authorization: string };
  isAuthenticated: boolean;
  user?: User;
};

const loggedInState: AuthState = {
  accessToken: "accessToken",
  headers: { Authorization: `Bearer accessToken` },
  isAuthenticated: true,
  user: loopAdminUser,
};

const loggedOutState: AuthState = {
  accessToken: undefined,
  headers: undefined,
  isAuthenticated: false,
  user: undefined,
};

export function useInitMockAuth(): InitAuth {
  const [state, setState] = useState(loggedOutState);

  return {
    ...state,
    isLoading: false,
    loginWithRedirect: async () => {
      setState(loggedInState);
    },
    logout: async () => {
      setState(loggedOutState);
      window.location.assign("/");
    },
  };
}
