import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";

const ViewVersionContext = createContext<[number, Dispatch<SetStateAction<number>>]>([
  0,
  () => {
    /**/
  },
]);

export function ViewVersionProvider({ children }: PropsWithChildren) {
  const state = useState<number>(0);

  return <ViewVersionContext.Provider value={state}>{children}</ViewVersionContext.Provider>;
}

export function useViewVersion() {
  return useContext(ViewVersionContext);
}
