import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/styled-system";
import { semanticTokens } from "@design-system";
import { text } from "@design-system/text.ts";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle(({ theme, colorMode }: StyleFunctionProps) => ({
  tablist: {
    color: semanticTokens(theme, colorMode).text.secondary,
    borderBottomColor: semanticTokens(theme, colorMode).border.border,
    borderBottomWidth: "1px",
  },
  tab: {
    height: "3rem",
    py: "0.25rem",
    _selected: {
      borderBottomColor: "unset",
      color: semanticTokens(theme, colorMode).text.primary,
    },
    _hover: {
      bg: "unset",
      color: semanticTokens(theme, colorMode).text.primary,
    },
    ...text.label,
  },
}));

export const tabsStyleConfig = defineMultiStyleConfig({
  baseStyle,
  variants: {
    center: {
      tab: {
        px: "0.25rem",
        textAlign: "center",
        minWidth: "6rem",
      },
    },
    left: {
      tablist: {
        pl: "1.25rem",
      },
      tab: {
        px: 0,
        pl: 0,
        pr: "1.25rem",
      },
    },
  },
  defaultProps: {
    variant: "left",
  },
});
