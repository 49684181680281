import { defineStyleConfig } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";
export const dropBoxStyleConfig = defineStyleConfig({
  baseStyle: {
    height: "1.5rem",
    border: "1px",
    borderStyle: "solid",
  },
  variants: {
    ok: ({ theme, colorMode }) => ({
      bgColor: semanticTokens(theme, colorMode).surface.invert.accent.secondary,
      borderColor: semanticTokens(theme, colorMode).border.accent.primary,
    }),
    error: ({ theme, colorMode }) => ({
      bgColor: semanticTokens(theme, colorMode).surface.secondary,
      borderColor: semanticTokens(theme, colorMode).border.classic.secondary,
    }),
  },
});

export type DropBoxVariant = "ok" | "error";
