export type Point = [number, number, number];
export type ControlVector = [Point, Point];

export interface Path {
  type: string;
  points: Point[];
  controlVectors: ControlVector[];
}

export interface PathCollectionAttributes {
  stitchDensity?: {
    course?: number | null;
    wale?: number | null;
  } | null;
}

export interface PathCollection {
  id: string;
  name: string;
  modelId: string;
  paths: Path[];
  usage?: "guide" | "zone" | null;
  attributes?: PathCollectionAttributes | null;
}

export type Unit = "mm" | "cm" | "m" | "in";

export const Units: { [key in Unit]: number } = {
  mm: 0.1,
  cm: 1,
  m: 100,
  in: 2.54,
};

export interface ModelAttributes {
  stitchDensity?: {
    course?: number | null;
    wale?: number | null;
  };
}

export interface Model {
  id: string;
  name: string;
  unit?: Unit;
  url: string;
  decimatedUrl?: string;
  pathCollections: PathCollection[];
  attributes?: ModelAttributes;
}

export interface Tag {
  id: string;
  label: string;
  color: string;
}

export interface Member {
  id: string;
  name: string;
  avatar: string;
}

export interface Project {
  id: string;
  organizationId: string;
  name: string;
  thumbnail?: string;
  description?: string;
  models: Model[];
  tags: Tag[];
  members: Member[];
  createdAt: Date;
  updatedAt: Date;
  parentFolderId?: string;
}
