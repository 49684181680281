import BackendApi from "@api/BackendApi";
import { AuthHeaders } from "@auth";
import { Path, Project } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { ModelsStore, PathCollections } from "@state/models";
import { PathAction, PathActionType } from "@state/models/reducers/pathReducer";
import { recordToArray } from "./collection";

export async function refreshPathCollection(
  collection: PathCollection,
  dispatch: (path: number, action: PathAction) => void,
) {
  const paths = await collection.cpp.getPaths();
  for (let j = 0; j < paths.length; j++) {
    const state = await paths[j].getState();
    const curves = await paths[j].getCurveSegmentsAsFloats();
    dispatch(j, { type: PathActionType.SET_STATE, value: state });
    dispatch(j, { type: PathActionType.SET_CURVES, value: curves });
  }
}

export async function savePathCollection(
  headers: AuthHeaders["headers"],
  projectId: Project["id"],
  modelId: Model["id"],
  collection: PathCollection,
) {
  const paths = (await collection.cpp.getState()) as Path[];
  const usage = collection.usage;

  const request = {
    headers,
    params: {
      projectId: projectId,
      modelId: modelId,
      pathCollectionId: collection.id!,
    },
    body: {
      paths: paths,
      usage,
    },
  };

  return BackendApi.updatePathCollection(request);
}

export async function refreshPathCollections(
  modelId: Model["id"],
  pathCollections: PathCollections,
  dispatchPathEdit: ModelsStore["dispatchPathEdit"],
  dispatchPathNew: ModelsStore["dispatchPathNew"],
) {
  const { collections, newCollection } = pathCollections[modelId];

  const collectionValues = recordToArray(collections);
  for (let i = 0; i < collectionValues.length; i++) {
    const collection = collectionValues[i];
    await refreshPathCollection(collection, (j, action) => dispatchPathEdit(modelId, collection.id!, j, action));
  }

  await refreshPathCollection(newCollection, (j, action) => dispatchPathNew(modelId, j, action));
}
