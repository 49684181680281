import { cssVar, defineStyleConfig } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";

const arrowBackground = cssVar("popper-arrow-bg").variable;
const arrowSize = cssVar("popper-arrow-size").variable;

export const tooltipStyleConfig = defineStyleConfig({
  baseStyle: ({ theme, colorMode }) => ({
    bg: semanticTokens(theme, colorMode).surface.classic.primary,
    boxShadow: "md",
    color: semanticTokens(theme, colorMode).text.classic.primary,
    borderRadius: "none",
    [arrowBackground]: semanticTokens(theme, colorMode).surface.classic.primary,
    [arrowSize]: "0.5rem",
  }),
});
