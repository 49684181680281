import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";
import { text } from "@design-system/text";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle(({ theme, colorMode }) => ({
  field: {
    ...text["xs-regular"],
    borderColor: "none",
    background: semanticTokens(theme, colorMode).surface.classic.secondary,
    color: semanticTokens(theme, colorMode).text.classic.primary,
    width: "full",
    p: "0.75rem",
    borderRadius: "none",
    _placeholder: {
      color: semanticTokens(theme, colorMode).text.secondary,
    },
    _active: {
      color: semanticTokens(theme, colorMode).text.primary,
    },
    _disabled: {
      opacity: 0.6,
    },
    _focus: {
      backgroundColor: "unset",
      outlineColor: semanticTokens(theme, colorMode).border.border,
      outlineWidth: "1px",
      outlineOffset: "-1px",
    },
    _hover: {
      backgroundColor: semanticTokens(theme, colorMode).surface.classic.tertiary,
      borderColor: semanticTokens(theme, colorMode).border.border,
    },
  },
  element: {
    width: "unset",
  },
}));

const compact = definePartsStyle({
  field: {
    p: 0,
  },
});

export const inputStyleConfig = defineMultiStyleConfig({
  baseStyle,
  variants: {
    input: baseStyle,
    compact,
  },
  defaultProps: {
    variant: "input",
  },
});
