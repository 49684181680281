import { create } from "zustand";

interface LineVizStore {
  lineVizOptions: {
    showLine: boolean;
    showTube: boolean;
    dashed: boolean;
    dashSize: number;
    dashScale: number;
    dashOffset: number;
    color: string;
    lineWidth: number;
    outline: boolean;
    outlineColor: string;
    outlineOffset: number;
    occludedOpacity: number;
  };
  setLineVizOptions: (lineVizOptions: LineVizStore["lineVizOptions"]) => void;
  logLineVizOptions: () => void;
}

export const useLineVizStore = create<LineVizStore>((set, get) => ({
  lineVizOptions: {
    showLine: true,
    showTube: false,
    dashed: false,
    dashSize: 0.7,
    dashScale: 3.0,
    dashOffset: 0.0,
    color: "#666666",
    lineWidth: 2.0,
    outline: false,
    outlineOffset: 4.0,
    outlineColor: "#ffffff",
    occludedOpacity: 0.25,
  },
  setLineVizOptions: (lineVizOptions: LineVizStore["lineVizOptions"]) => {
    set(() => ({ lineVizOptions: lineVizOptions }));
  },
  logLineVizOptions: () => {
    console.log("lineViz options:");
    console.table(get().lineVizOptions);
  },
}));

interface ZonePaletteStore {
  zonePalette: {
    A: string;
    B: string;
    C: string;
    D: string;
    E: string;
    F: string;
    G: string;
    H: string;
    I: string;
    J: string;
    K: string;
  };
  setZonePalette: (zonePalette: ZonePaletteStore["zonePalette"]) => void;
}

export const useZonePaletteStore = create<ZonePaletteStore>((set) => ({
  zonePalette: {
    A: "#FF3B2C",
    B: "#E68608",
    C: "#6941C6",
    D: "#12B76A",
    E: "#009CF2",
    F: "#F75F2D",
    G: "#3FDFC4",
    H: "#D36EF6",
    I: "#F6D259",
    J: "#39E4F3",
    K: "#2AF4B0",
  },
  setZonePalette: (zonePalette: ZonePaletteStore["zonePalette"]) => {
    set(() => ({ zonePalette: zonePalette }));
  },
}));
