import { formAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, SystemStyleObject } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(formAnatomy.keys);

const activeLabelStyles: SystemStyleObject = {
  transform: "scale(0.85) translateX(-12px) translateY(-12px)",
};

export const formStyleConfig = defineMultiStyleConfig({
  variants: {
    floating: ({ theme }) => ({
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
          {
            ...activeLabelStyles,
          },
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          pointerEvents: "none",
          mx: 3,
          px: 1,
          my: 2,
          background: "inherit",
          transformOrigin: "left top",
          color: theme.semanticTokens["chakra-placeholder-color"],
        },
      },
    }),
  },
});
