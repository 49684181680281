import { extendTheme, StyleFunctionProps, ThemeConfig, withDefaultColorScheme } from "@chakra-ui/react";
import { ChakraTheme } from "@chakra-ui/theme";
import components from "./component-styles";
import { base, classic, semanticTokens } from "./semantic-tokens";
import { typography } from "./typography";

const styles = {
  global: ({ theme, colorMode }: StyleFunctionProps) => ({
    "&::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: semanticTokens(theme, colorMode).surface.primary,
    },
    "&::-webkit-scrollbar-corner": {
      width: "8px",
      backgroundColor: semanticTokens(theme, colorMode).surface.primary,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: semanticTokens(theme, colorMode).surface.secondary,
    },
  }),
};

const common: Partial<ChakraTheme> & ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
  components,
  styles,
  ...typography,
};

const baseTheme: Partial<ChakraTheme> & ThemeConfig = {
  ...common,
  semanticTokens: {
    colors: {
      "chakra-body-text": {
        _light: base.light.text.primary,
        _dark: base.dark.text.primary,
      },
      "chakra-body-bg": {
        _light: base.light.surface.primary,
        _dark: base.dark.surface.primary,
      },
      "chakra-border-color": {
        _light: base.light.border.border,
        _dark: base.dark.border.border,
      },
      "chakra-inverse-text": {
        _light: base.light.text.invert.primary,
        _dark: base.dark.text.invert.primary,
      },
      "chakra-placeholder-color": {
        _light: base.light.text.secondary,
        _dark: base.dark.text.secondary,
      },
    },
    shadows: {
      sm: {
        _light: "0px 1px 2px 0px rgba(16, 24, 40, 0.10)",
        _dark: "0px 1px 2px 0px rgba(0, 0, 0, 0.20)",
      },
      md: {
        _light: "0px 0px 2px 0px rgba(16, 24, 40, 0.04), 0px 2px 6px 0px rgba(16, 24, 40, 0.16)",
        _dark: "0px 2px 6px 0px rgba(0, 0, 0, 0.36), 0px 0px 2px 0px rgba(0, 0, 0, 0.10);",
      },
      lg: {
        _light: "0px 2px 30px 0px rgba(16, 24, 40, 0.02), 0px 6px 10px 0px rgba(16, 24, 40, 0.16)",
        _dark: "0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 10px 36px 0px rgba(0, 0, 0, 0.50)",
      },
      outline: {
        _light: base.light.border.accent.primary,
        _dark: base.dark.border.accent.primary,
      },
    },
  },
};

const classicTheme: Partial<ChakraTheme> & ThemeConfig = {
  ...common,
  semanticTokens: {
    colors: {
      "chakra-body-text": classic.text.primary,
      "chakra-body-bg": classic.surface.primary,
      "chakra-border-color": classic.border.border,
      "chakra-inverse-text": classic.text.invert.primary,
      "chakra-placeholder-color": classic.text.secondary,
    },
    shadows: {
      sm: "0px 1px 2px 0px rgba(0, 0, 0, 0.20)",
      md: "0px 2px 6px 0px rgba(0, 0, 0, 0.36), 0px 0px 2px 0px rgba(0, 0, 0, 0.10)",
      lg: "0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 10px 36px 0px rgba(0, 0, 0, 0.50)",
      outline: classic.border.accent.primary,
    },
  },
};

export const themes = {
  base: extendTheme(withDefaultColorScheme({ colorScheme: "base" }), baseTheme, { theme: "base" }),
  classic: extendTheme(withDefaultColorScheme({ colorScheme: "classic" }), classicTheme, { theme: "classic" }),
};
