import { useTranslation as useI18nTranslation } from "react-i18next";

export type TranslationFunction = (key: string, options?: Record<string, string>, fallback?: string) => string;

export function useTranslation(prefix?: string): {
  t: TranslationFunction;
  language: string;
} {
  const {
    t: i18nT,
    i18n: { language },
  } = useI18nTranslation(undefined, { keyPrefix: prefix });

  return {
    t: (key: string, options?: Record<string, string>, fallback?: string) =>
      i18nT(key, fallback || `?${key}?`, options),
    language,
  };
}
