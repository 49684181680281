import { AuthProvider } from "@auth/AuthProvider.tsx";
import { ChakraProvider, ColorModeScript, CSSReset } from "@chakra-ui/react";
import { SelectedThemeProvider, useSelectedTheme } from "@design-system";
import { initIntegrations } from "@integrations";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18n";
import { ReactQueryDevtools } from "react-query/devtools";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient();

const AppWithIntegrations = initIntegrations(App);

export function Root() {
  const { chakraTheme } = useSelectedTheme();

  return (
    <ChakraProvider theme={chakraTheme}>
      <QueryClientProvider client={queryClient}>
        <CSSReset />
        <AppWithIntegrations />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

root.render(
  <>
    <ColorModeScript initialColorMode="system" />

    <AuthProvider>
      <SelectedThemeProvider>
        <Root />
      </SelectedThemeProvider>
    </AuthProvider>
  </>,
);
