import { PathCollection } from "@models/project";
import {
  convertPath as convertPathUtil,
  createPathCollection,
  deletePathCollection,
  resetPath as resetPathUtil,
} from "@utils/project/pathCollections";
import { ModelsStore } from "../types";
import { GetState, SetState } from "zustand";
import { PathAction, PathActionType, reducer as pathReducer } from "../reducers/pathReducer";
import { arrayToRecord } from "@utils/project/collection";
import { cloneDeep, defaultsDeep } from "lodash";

export const pathCollectionsSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>) => ({
  pathCollections: {},
  dispatchPathEdit: (modelId: string, collectionId: string, pathIdx: number, action: PathAction) => {
    let updatedCollection: PathCollection | undefined;
    set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      const collection = collections[collectionId];
      updatedCollection = {
        ...collection,
        paths: collection.paths.map((path, jdx) => (jdx === pathIdx ? pathReducer(path, action) : path)),
      };
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            newCollection,
            collections: {
              ...collections,
              [collectionId]: updatedCollection,
            },
          },
        },
      };
    });
    return updatedCollection!;
  },
  dispatchPathNew: (modelId: string, pathIdx: number, action: PathAction) => {
    let updatedCollection: PathCollection | undefined;
    set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      updatedCollection = {
        ...newCollection,
        paths: newCollection.paths.map((path, idx) => (idx === pathIdx ? pathReducer(path, action) : path)),
      };
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            newCollection: updatedCollection,
            collections,
          },
        },
      };
    });
    return updatedCollection!;
  },
  convertPath: async (
    modelId: string,
    collectionId: string,
    subpathIdx: number,
    type: "BezierPath" | "HarmonicPath",
  ) => {
    const pathCollections = get().pathCollections;
    const { collections } = pathCollections[modelId];
    const collection = collections[collectionId];
    const pathCpp = await convertPathUtil(collection, subpathIdx, type);
    return get().dispatchPathEdit(modelId, collectionId, subpathIdx, {
      type: PathActionType.UPDATE_CPP,
      cpp: pathCpp,
      pathType: type,
    });
  },
  convertNewPath: async (modelId: string, subpathIdx: number, type: "BezierPath" | "HarmonicPath") => {
    const pathCollections = get().pathCollections;
    const { newCollection } = pathCollections[modelId];
    const pathCpp = await convertPathUtil(newCollection, subpathIdx, type);
    return get().dispatchPathNew(modelId, subpathIdx, {
      type: PathActionType.UPDATE_CPP,
      cpp: pathCpp,
      pathType: type,
    });
  },
  resetPath: async (modelId: string, collectionId: string, subpathIdx: number) => {
    const pathCollections = get().pathCollections;
    const { collections } = pathCollections[modelId];
    const collection = collections[collectionId];
    const pathCpp = await resetPathUtil(collection, subpathIdx);
    return get().dispatchPathEdit(modelId, collectionId, subpathIdx, {
      type: PathActionType.UPDATE_CPP,
      cpp: pathCpp,
      pathType: "Path",
    });
  },
  resetNewPath: async (modelId: string, subpathIdx: number) => {
    const pathCollections = get().pathCollections;
    const { newCollection } = pathCollections[modelId];
    const pathCpp = await resetPathUtil(newCollection, subpathIdx);
    return get().dispatchPathNew(modelId, subpathIdx, {
      type: PathActionType.UPDATE_CPP,
      cpp: pathCpp,
      pathType: "Path",
    });
  },
  clearNewPathCollection: async (modelId: string) => {
    const models = get().models;
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;
    const nextNewCollection = await createPathCollection(model, "Path");
    return set(({ pathCollections }) => {
      const { collections } = pathCollections[modelId];
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: { ...collections },
            newCollection: nextNewCollection,
          },
        },
      };
    });
  },
  pushPathCollection: async (modelId: string, pathCollectionId: string) => {
    const models = get().models;
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;
    const nextNewCollection = await createPathCollection(model, "Path");
    return set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      newCollection.id = pathCollectionId;
      collections[pathCollectionId] = newCollection;
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: collections,
            newCollection: nextNewCollection,
          },
        },
        selectedObjects: [{ type: "path", id: pathCollectionId, modelId: modelId }],
      };
    });
  },
  pushPathCollections: async (modelId: string, newPathCollections: PathCollection[]) => {
    const models = get().models;
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;
    return set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];

      const result = arrayToRecord(newPathCollections, (collection) => collection.id!);

      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: { ...collections, ...result },
            newCollection: newCollection,
          },
        },
        selectedObjects: [],
      };
    });
  },
  changePathCollection: (modelId: string, pathCollectionId: string, change: Partial<PathCollection>) => {
    const updatedCollections: PathCollection[] = [];
    set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      const changedCollections = { ...collections };
      if ("usage" in change && change["usage"] === "guide") {
        for (const key in collections) {
          if (collections[key].usage === "guide") {
            changedCollections[key] = { ...changedCollections[key], usage: null };
            updatedCollections.push(changedCollections[key]);
            break;
          }
        }
      }
      const mergedChange = { ...change };
      if ("attributes" in change) {
        const copy: PathCollection["attributes"] = cloneDeep(change["attributes"]) as PathCollection["attributes"];
        defaultsDeep(copy, changedCollections[pathCollectionId]["attributes"]);
        mergedChange["attributes"] = copy;
      }
      const collection = {
        ...collections[pathCollectionId],
        ...mergedChange,
      };
      updatedCollections.push(collection);
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: {
              ...changedCollections,
              [pathCollectionId]: collection,
            },
            newCollection,
          },
        },
      };
    });
    return updatedCollections;
  },
  changePathCollectionNew: (modelId: string, change: Partial<PathCollection>) => {
    let updatedCollection: PathCollection | undefined;
    set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      const mergedChange = { ...change };
      if ("attributes" in change) {
        const copy: PathCollection["attributes"] = cloneDeep(change["attributes"]) as PathCollection["attributes"];
        defaultsDeep(copy, newCollection["attributes"]);
        mergedChange["attributes"] = copy;
      }
      updatedCollection = {
        ...newCollection,
        ...mergedChange,
      };
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections,
            newCollection: updatedCollection,
          },
        },
      };
    });
    return updatedCollection!;
  },
  removePathCollection: (modelId: string, pathCollectionId: string) => {
    // Remove by uuid instead of index since multiple collections can be deleted sequentially which changes expected indices
    const models = get().models;
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;
    return set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      deletePathCollection(collections[pathCollectionId]);
      delete collections[pathCollectionId];
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: collections,
            newCollection: newCollection,
          },
        },
        selectedObjects: [],
      };
    });
  },
});
