import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle(({ theme, colorMode }) => ({
  container: {
    px: "1rem",
    py: "0.75rem",
  },
  label: {
    alignItems: "center",
    color: semanticTokens(theme, colorMode).text.classic.primary,
    display: "flex",
    gap: "0.5rem",
  },
  control: {
    bg: semanticTokens(theme, colorMode).surface.classic.primary,
    borderColor: semanticTokens(theme, colorMode).border.classic.primary,
    borderRadius: "none",
    borderWidth: "1px",
    width: "1rem",
    height: "1rem",
    paddingBottom: "0.14rem",
    _checked: {
      bg: semanticTokens(theme, colorMode).surface.invert.accent.primary,
      color: semanticTokens(theme, colorMode).icons.invert,
      borderColor: semanticTokens(theme, colorMode).surface.invert.accent.secondary,
      _focus: {
        bg: semanticTokens(theme, colorMode).surface.invert.accent.primary,
      },
      _hover: {
        bg: semanticTokens(theme, colorMode).surface.invert.accent.primary,
        borderColor: semanticTokens(theme, colorMode).surface.invert.accent.secondary,
      },
    },
    _disabled: {
      opacity: 0.6,
    },
    _focus: {
      bg: semanticTokens(theme, colorMode).surface.classic.primary,
    },
    _hover: {
      bg: semanticTokens(theme, colorMode).surface.classic.secondary,
      borderColor: semanticTokens(theme, colorMode).border.accent.primary,
    },
  },
}));

export const checkboxStyleConfig = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {},
});
