import { OrganizationMember } from "@auth/AuthContext.ts";

export default {
  id: "localAdminUserId",
  name: "Local Admin",
  avatar: "https://source.boringavatars.com/beam/120/Local%20Admin",
  email: "local-admin@variant3d.io",
  memberRole: "admin",
  createdAt: new Date(2024, 0, 1),
  updatedAt: new Date(2024, 0, 1),
  memberStatus: "active",
} as OrganizationMember;
