import { OrganizationMember } from "@auth/AuthContext.ts";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export type MemberColumnSorting = {
  id: string;
  desc: boolean;
};

export type MemberGlobalFilter = {
  role: OrganizationMember["memberRole"] | "all";
  status: OrganizationMember["memberStatus"] | "all";
};

export type MemberSortingState = MemberColumnSorting[];

export interface MembersToolbarState {
  filter: MemberGlobalFilter;
  sort: MemberSortingState;
  setRoleFilter: (value: OrganizationMember["memberRole"] | "all") => void;
  setStatusFilter: (value: OrganizationMember["memberStatus"] | "all") => void;
  setSort: (sort: MembersToolbarState["sort"]) => void;
}

export interface MembersTableState {
  filter: MemberGlobalFilter;
  filterResult: OrganizationMember[];
  sort: MemberSortingState;
  setSort: (sort: MembersToolbarState["sort"]) => void;
}

export interface MembersDataState {
  members: OrganizationMember[];

  setMembers: (members: OrganizationMember[]) => void;
}

type MembersState = MembersDataState & MembersTableState & MembersToolbarState;

export const useMembersState = create(
  subscribeWithSelector<MembersState>((set) => ({
    filter: {
      role: "all",
      status: "all",
    },
    filterResult: [],
    members: [],
    sort: [{ id: "updatedAt", desc: true }],

    setRoleFilter: (role) => set(({ filter }) => ({ filter: { ...filter, role } })),
    setStatusFilter: (status) => set(({ filter }) => ({ filter: { ...filter, status } })),
    setMembers: (members) => set(() => ({ members })),
    setSort: (sort) => set(() => ({ sort })),
  })),
);
