import { Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { config } from "./config.ts";

export function AuthProvider({ children }: { children: ReactNode }) {
  const { domain, clientId } = config;
  const authorizationParams = {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : {}),
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      useCookiesForTransactions={false}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="localstorage"
      httpTimeoutInSeconds={3}
      authorizeTimeoutInSeconds={10}
    >
      {children}
    </Auth0Provider>
  );
}
