import { Dispatch, SetStateAction, useState } from "react";

export function useLocalState<V = undefined>(
  key: string,
  initial: V,
  deserializer = (v: string): V => JSON.parse(v),
  serializer = (v: V): string => JSON.stringify(v),
): [V, Dispatch<SetStateAction<V>>] {
  const [value, setValue] = useState<V>(() => {
    if (window && window.localStorage) {
      const saved = window.localStorage.getItem(key);

      if (saved) {
        try {
          return deserializer(saved);
        } catch (e) {
          console.log(`unable to deserialize ${saved}`, e);
          window.localStorage.removeItem(key);
        }
      }
    }

    return initial;
  });

  return [
    value,
    (action) => {
      let newValue;

      if (typeof action === "function") {
        newValue = (action as (prevState: V) => V)(value);
      } else {
        newValue = action as V;
      }

      if (window.localStorage) {
        if (value) {
          window.localStorage.setItem(key, serializer(newValue));
        } else {
          window.localStorage.removeItem(key);
        }
      }

      setValue(newValue);
    },
  ];
}
