import BackendApi from "@api/BackendApi.ts";
import { AuthContext } from "@auth/AuthContext";
import { useInitAuth } from "@auth/useInitAuth.ts";
import { Center } from "@chakra-ui/react";
import { Loading } from "@fragments";
import { ViewVersionProvider } from "@hooks";
import { Onboarding } from "@routes";
import { wrapCreateBrowserRouter } from "@sentry/react";
import initV3dApi from "@utils/project/initV3dApi";
import { lazy, Suspense } from "react";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";

export const Home = lazy(() => import("./routes/Home"));
export const Project = lazy(() => import("./routes/Project"));
export const Playground = lazy(() => import("./routes/Playground"));

export default function App() {
  const { accessToken, isLoading, error, headers, loginWithRedirect, logout, user } = useInitAuth();

  if (error) {
    return (
      <Center flex="1 1 auto" h="full">
        Oops... {error.message}
      </Center>
    );
  }

  if (isLoading) {
    return <Loading height="full" />;
  }

  if (!accessToken || !headers || !user) {
    return <Onboarding loginWithRedirect={loginWithRedirect} />;
  }

  const router = wrapCreateBrowserRouter(createHashRouter)([
    {
      index: true,
      element: <Navigate to="/projects" />,
    },
    {
      path: "/*",
      element: (
        <Suspense fallback={<Loading />}>
          <Home />
        </Suspense>
      ),
    },
    {
      path: "projects/:id",
      element: (
        <Suspense fallback={<Loading />}>
          <Project />
        </Suspense>
      ),
      loader: async ({ params: { id } }: { params: { id: string } }) => {
        const project = await BackendApi.getProject({ headers, params: { id } });
        const v3dApi = await initV3dApi();
        return {
          project,
          v3dApi,
        };
      },
    },
    {
      path: "/playground",
      element: (
        <Suspense fallback={<Loading />}>
          <Playground />
        </Suspense>
      ),
    },
  ]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        headers,
        user,
        logout,
      }}
    >
      <ViewVersionProvider>
        <RouterProvider router={router} />
      </ViewVersionProvider>
    </AuthContext.Provider>
  );
}
