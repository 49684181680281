import { Theme } from "@chakra-ui/react";
import "@fontsource/inter";
import "@fontsource/inter/300.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/900.css";
import "./fonts/mabry-pro/stylesheet.css";

export const typography: Partial<Theme> = {
  fonts: {
    heading: "Mabry Pro",
    body: "Inter",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    "3xs": "0.625rem",
    "2xs": "0.75rem",
    xs: "0.8125rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.0625rem",
    xl: "1.125rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.125rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "7.5rem",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.125,
    short: 1.125,
    base: 1.25,
    tall: 1.5,
    taller: "1.625rem",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
};
